<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="eraports"
          :search="search"
          title="E-Raport"
          subtitle="Halaman untuk menambahkan raport"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          :total-pages.sync="totalPages"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @show-image="showImage"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || eraport.file === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah E-Raport': 'Edit E-Raport'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-autocomplete
            v-model="eraport.student_uuid"
            label="Nama Siswa"
            :items="students"
            item-text="name"
            item-value="uuid"
            outlined
            clearable
            dense
            @change="getStudentUuid(eraport.student_uuid)"
          >
          </v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-model="eraport.semester"
            label="Semester"
            :items="semesters"
            outlined
            clearable
            dense
          >
          </v-autocomplete>
        </div>
        <div>
          <v-autocomplete
            v-model="eraport.tahun_ajaran"
            label="Tahun Ajaran"
            :items="study_years"
            return-object
            outlined
            clearable
            dense
          >
          </v-autocomplete>
        </div>
        <div>
          <FileInput
            v-if="dialog =='add'"
            v-model="initialFile"
            :value="eraport.file"
            :prependicon="icons.mdiImageMultipleOutline"
            :message="message"
            label="File"
            @input="getFile"
          ></FileInput>
          <FileInput
            v-else-if="dialog =='edit'"
            v-model="initialFile"
            dense
            outlined
            :prependicon="icons.mdiImageMultipleOutline"
            label="File"
            @input="getFile"
          >
          </FileInput>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus E-Raport"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
    <ImageOverlay
      :visible="imageOverlay"
      :width="600"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="eraport.file"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { mdiImageMultipleOutline } from '@mdi/js'
import ConfirmDialog from '../../components/ConfirmDialog.vue'
import DataTablePagination from '../../components/DataTablePagination.vue'
import FileInput from '../../components/FileInput.vue'
import ImageOverlay from '../../components/ImageOverlay.vue'
import MainCard from '../../components/MainCard.vue'
import ModalDialog from '../../components/ModalDialog.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  name: 'Major',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    ImageOverlay,
    MainCard,
    SkeletonLoaderTable,
    FileInput,
  },
  data() {
    return {
      message: 'File .jpg/.png/.pdf, max. 2MB',
      icons: { mdiImageMultipleOutline },
      school: [],
      school_uuid: '',
      page: 1,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingTable: true,
      totalItems: 10,
      totalPages: 0,
      search: '',
      dialog: '',
      service: 'eraport',
      eraports: [],
      students: [],
      student_uuid: '',
      eraport: {
        student_uuid: '',
        file: null,
        semester: '',
        tahun_ajaran: '',
      },
      class_advisors: [],
      class_advisor_uuid: '',
      modalDialog: false,
      confirmDialog: false,
      imageOverlay: false,
      overlay: false,
      semesters: ['Genap', 'Ganjil'],
      study_years: ['2018/2019', '2019/2020', '2020/2021', '2021/2022'],
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Siswa', value: 'student.full_name' },
        { text: 'Semester', value: 'semester' },
        { text: 'Tahun Ajaran', value: 'tahun_ajaran' },
        { text: 'Foto', value: 'file' },

        { text: 'Aksi', value: 'actions' },
      ],
      filterQuery: {
        student: '',
      },
      formValid: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.eraport.file === 'string') {
          return new File([this.eraport.file], this.eraport.file, { type: 'text/plain' })
        }

        return this.eraport.file
      },
      set() {
        return this.eraport.file
      },
    },
  },
  watch: {
    eraport: {
      handler() {
        const valid = []
        const requiredField = ['student_uuid', 'semester', 'tahun_ajaran']
        Object.entries(this.eraport).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listRaport(this.filterQuery)
      },
    },
  },
  async mounted() {
    await this.getSchoolUuid()
    await this.listRaport()
    this.listStudent()
  },
  methods: {
    resetForm() {
      this.eraport.student_uuid = ''
      this.eraport.semester = ''
      this.eraport.tahun_ajaran = ''
      this.eraport.file = null
    },
    getFile(data) {
      this.eraport.file = data
    },
    getStudentUuid(uuid) {
      this.eraport.student_uuid = uuid
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.class_advisors = JSON.parse(localStorage.getItem('user')).user.details
      this.class_advisors.forEach(el => {
        el.teacher.class_advisor.forEach(el => {
          this.class_advisor_uuid = el.uuid
        })
      })
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
      this.resetForm()
    },

    async listRaport(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        school_uuid: this.school_uuid,
        'class_uuid[]': this.class_advisor_uuid,
      }).then(
        ({ data }) => {
          this.eraports = data.data.map((eraport, index) => ({
            ...eraport,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async listStudent(params = {}) {
      await this.$services.ApiServices.list('student', {
        ...params,
        per_page: 'all',
        school_uuid: this.school_uuid,
      }).then(
        ({ data }) => {
          this.students = data.data
        },
        err => console.error(err),
      )
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('school_uuid', this.school_uuid)
      this.formData.append('student_uuid', this.eraport.student_uuid)
      this.formData.append('file', this.eraport.file)
      this.formData.append('semester', this.eraport.semester)
      this.formData.append('tahun_ajaran', this.eraport.tahun_ajaran)
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.listRaport(this.filterQuery)
    },
    async showFormEdit(uuid) {
      this.eraport.student_uuid = uuid
      this.dialog = 'edit'
      this.eraport.uuid = uuid
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.eraport = { ...data.data, student_uuid: data.data.student.uuid }
      })
      this.modalDialog = true
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('student_uuid', this.eraport.student.uuid)
      this.formData.append('semester', this.eraport.semester)
      this.formData.append('tahun_ajaran', this.eraport.tahun_ajaran)
      this.formData.append('file', this.eraport.file)
      if (typeof this.eraport.file === 'string' || this.eraport.file === null) {
        this.formData.delete('file')
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.eraport.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listRaport(this.filterQuery)
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.eraport.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.eraport.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listRaport(this.filterQuery)
    },
    async showImage(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.eraport = data.data
          this.eraport.file = data.data.file
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },
    closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },
    async searchHandler(search) {
      this.filterQuery.student = search
      this.page = 1
      await this.listRaport(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
